/*================ Mixins ================*/
@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: 0px) {
      @content;
    }
  }

  @else if $point == medium {
    @media (min-width: 640px) {
      @content;
    }
  }

  @else if $point == deadstockmedium {
    @media (min-width: 768px) {
      @content;
    }
  }
}

/*================ Variables ================*/
$main-text: #000000;
$secondary-text: #666666;
$white: #FFFFFF;
$btn-color: #FFEE00;

$deadstock-btn-color: #000000;
$deadstock-btn-hover: #555555;
$deadstock-body-font: "GT America Standard Regular", sans-serif;
$deadstock-heading-font: "GT America Standard Medium", sans-serif;
$deadstock-label-font: "GT America Mono Regular";

$size-btn-color: #000000;
$size-btn-hover: #FF600B;

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.45) !important;
  // opacity: 0.45;

  .ReactModal__Content {
    background-color: red;
    border-radius: 0 !important;
    padding: 20px !important;
    // margin: 0 auto;
    width: 85%;

    @include breakpoint(medium) {
      padding: 24px !important;
      width: 100%;
      max-width: 635px;
    }

    .returns-app__modal {
      &.jdsports {
        .returns-app__modal--close {
          display: flex;
          justify-content: flex-end;

          @include breakpoint(medium) {
            float: right;
            max-width: 25%;
          }

          span {
            &:after {
              content: url("./ico-close.svg");
            }

            &:hover {
              cursor: pointer;

              &:after {
                content: url("./ico-close-alt.svg");
              }
            }
          }
        }

        .returns-app__modal--title {
          font-size: 23px;
          line-height: 28px;
          font-weight: 700;
          color: $main-text;
          text-transform: uppercase;
          text-align: center;
          margin-top: 14px;
          margin-bottom: 16px;

          @include breakpoint(medium) {
            margin-top: 0;
            max-width: 320px;
            text-align: left;
          }
        }

        .returns-app__modal--message {
          font-size: 13px;
          line-height: 20px;
          color: $main-text;
          text-align: center;
          margin-top: 0;
          margin-bottom: 16px;

          @include breakpoint(medium) {
            text-align: left;
          }
        }

        .returns-app__modal--button {
          background-color: $white;
          border: 1px solid $main-text;
          padding: 15px 0;
          width: 100%;
          font-size: 14px;
          line-height: 16px;
          color: $main-text;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          transition: all .3s;

          @include breakpoint(medium) {
            max-width: 246px;
          }

          &:hover {
            cursor: pointer;
            background-color: $main-text;
            color: $white;
          }
        }
      } // End JD

      &.deadstock {
        .returns-app__modal--close {
          display: flex;
          justify-content: flex-end;

          @include breakpoint(deadstockmedium) {
          }

          span {
            &:after {
              content: url("./deadstock-ico-close.svg");
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .returns-app__modal--inner {
          word-break: break-all;

          @include breakpoint(deadstockmedium) {
            max-width: 100%;
            margin: 0 auto;
            padding: 0 50px;
          }
        }

        .returns-app__modal--title {
          font-family: $deadstock-heading-font;
          font-size: 18px;
          line-height: 26px;
          color: $main-text;
          text-align: center;
          margin-top: 12px;
          margin-bottom: 25px;
          text-transform: capitalize;

          @include breakpoint(deadstockmedium) {
            font-size: 22px;
            line-height: 30px;
            margin-top: 17px;
            margin-bottom: 16px;
            text-align: center;
          }
        }

        .returns-app__modal--message {
          font-size: 14px;
          line-height: 22px;
          color: $main-text;
          text-align: center;
          margin-top: 0;
          margin-bottom: 16px;
          word-break: break-word;

          @include breakpoint(deadstockmedium) {
            text-align: center;
            margin-bottom: 25px;
          }
        }

        .returns-app__modal--button {
          font-family: $deadstock-label-font;
          background-color: $deadstock-btn-color;
          border: 1px solid $main-text;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: $white;
          text-align: center;
          text-transform: uppercase;
          display: block;
          transition: all .3s;
          padding: 13px 30px;
          display: inline-flex;
          justify-content: center;
          transition: all .3s;
          max-width: 100%;
          height: 50px;

          @media (hover: hover) {
            &:hover {
              cursor: pointer;
              color: $white;
            }

            &:hover:after {
              cursor: pointer;
              color: $white;
              font-family: "icomoon";
              font-size: 18px;
              content: "\e911";
              margin-left: 10px;
            }
          }

          @include breakpoint(deadstockmedium) {
            max-width: 100%;
            margin: 0 auto;
          }
        }
      } // End Deadstock

      &.size {
        .returns-app__modal--close {
          display: flex;
          justify-content: flex-end;

          @include breakpoint(medium) {
          }

          span {
            &:after {
              content: url("./size-ico-close.svg");
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .returns-app__modal--inner {
          word-break: break-all;

          @include breakpoint(medium) {
            max-width: 353px;
            margin: 0 auto;
          }
        }

        .returns-app__modal--title {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          color: $main-text;
          text-align: left;
          margin-top: 12px;
          margin-bottom: 25px;

          @include breakpoint(medium) {
            margin-top: 17px;
            margin-bottom: 16px;
          }
        }

        .returns-app__modal--message {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: $main-text;
          text-align: left;
          margin-top: 0;
          margin-bottom: 16px;
          padding-bottom: 24px;
          border-bottom: 1px solid #DEDEDE;

          @include breakpoint(medium) {
            margin-bottom: 25px;
          }
        }

        .returns-app__modal--button {
          background-color: $size-btn-color;
          padding: 15px 0;
          width: 100%;
          font-size: 14px;
          line-height: 16px;
          color: $white;
          text-align: center;
          font-weight: 700;
          display: block;
          transition: all .3s;

          font-size: 16px;
          font-weight: 400;
          border: none;
          border-radius: 0;

          &:hover {
            cursor: pointer;
            background-color: $size-btn-hover;
            border: 1px solid $size-btn-hover;
            color: $white;
          }

          @include breakpoint(medium) {
            max-width: 353px;
            margin: 0 auto;
          }
        }
      } // End Size?
    }
  }
}