/*================ Mixins ================*/
@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: 0px) {
      @content;
    }
  }

  @else if $point == medium {
    @media (min-width: 768px) {
      @content;
    }
  }
}

/*================ Variables ================*/
$main-text: #000000;
$secondary-text: #888888;
$white: #FFFFFF;
$error: #dc181e;
$btn-color: #000000;
$btn-hover: #555555;
$table-border: #e6e7e8;
$table-background: #f0f0f0;
$body-font: "GT America Standard Regular", sans-serif;
$heading-font: "GT America Standard Medium", sans-serif;
$label-font: "GT America Mono Regular", serif;

.deadstock-returns-app-container {
  * {
    font-family: $body-font;
  }

  .returns-app {
    &__section {
      max-width: 635px;
      margin: 60px auto 0;

      @include breakpoint(medium) {
        margin: 72px auto 0;
      }

      &--back-button {
        font-size: 14px;
        line-height: 22px;
        color: $main-text;
        text-decoration: underline;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        background: none;
        border: none;
        padding: 0;

        @include breakpoint(medium) {
          margin-bottom: 40px;
        }

        &:hover {
          cursor: pointer;
        }

        &:before {
          content: url("./deadstock-ico-left.svg");
          margin-right: 8px;
        }
      }

      &--title {
        color: $main-text;
        font-family: $heading-font;
        font-size: 18px;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 20px;
        text-transform: capitalize;

        @include breakpoint(medium) {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 30px;
        }
      }

      &--customer-split {
        @include breakpoint(medium) {
        }
      }

      &--customer-title {
        font-size: 14px;
        line-height: 23px;
        color: $main-text;
        margin-top: 0;
        margin-bottom: 0;

        strong {
          font-weight: 400;
        }

        @include breakpoint(medium) {
          margin-bottom: 5px;
        }

        &.is--right {
          @include breakpoint(medium) {
          }
        }
      }

      &--form {
        &-title {
          color: $main-text;
          font-family: $heading-font;
          font-size: 18px;
          line-height: 26px;
          margin-top: 0;
          margin-bottom: 20px;
          text-transform: capitalize;

          @include breakpoint(medium) {
            font-size: 22px;
            margin-bottom: 30px;
          }
        }

        &-inputs {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 18px;

          @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
          }

          div {
            display: grid;
          }

          input {
            font-size: 15px;
            font-weight: 400;
            padding: 12px 11px;
            background: $white;
            border: 1px solid $main-text;
            color: $main-text;

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          .app-has-error {
            font-size: 10px;
            padding-top: 2px;
            color: $error;
          }

          .app-no-error {
            display: none;
          }

          &.full {
            grid-template-columns: 1fr;
            margin-top: 18px;
  
            @include breakpoint(medium) {
              margin-top: 16px;
            }
          }
        }

        &-errors {
          margin-top: 18px;

          @include breakpoint(medium) {
            margin-top: 16px;
          }

          p {
            margin: 0;
            font-size: 12px;
            color: $error;
          }
        }

        &-submit {
          font-family: $label-font;
          margin-top: 18px;
          background-color: $btn-color;
          border: 1px solid $btn-color;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1px;
          color: $white;
          text-align: center;
          text-transform: uppercase;
          padding: 13px 30px;
          display: inline-flex;
          justify-content: center;
          transition: all .3s;
          max-width: 100%;
          height: 50px;

          span {
            font-family: $label-font;
          }

          @media (hover: hover) {
            &:hover {
              cursor: pointer;
            }

            &:hover:after {
              color: $white;
              font-family: "icomoon";
              font-size: 18px;
              content: "\e911";
              margin-left: 10px;
            }
          }

          @include breakpoint(medium) {
            margin-top: 16px;
          }

          &-text {
            position: relative;

            // .is--loading {
            //   display: inline-block;
            //   position: absolute;
            //   left: -25px;

            //   &:before {
            //     content: url("./loading.gif");
            //   }
            // }
          }

          &:disabled {
            opacity: 0.5;
            transition: all 0s;

            &:hover {
              background-color: $btn-color;
              color: $white;
            }
          }
        }

        &-table {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 40px;
          font-size: 14px;
          border-collapse: collapse;
          border: 1px solid $table-border;

          @include breakpoint(medium) {
            margin-top: 20px;
          }

          th, td {
            &:after {
              display: none;
            }
          }

          td.table-body-product {
            text-align: left;
          }

          thead {
            background-color: $table-background;
            line-height: 15px;
            font-weight: 400;
            text-transform: uppercase;

            th {
              height: 58px;
              font-family: $label-font;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 1px;
              font-weight: 400;

              @media screen and (min-width: 768px) {
              }

              &:after {
                display: none;
              }
            }
          }

          &-price {
            font-family: $label-font;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            border-right: 1px solid $table-border;
          }

          tr {
            border: 1px solid $table-border;
          }

          input[type="checkbox"] {
            width: 16px;
            height: 16px;
            border: 0.1mm solid $main-text;
            padding: 0;
            background-color: $white;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:checked {
              background-color: $main-text;

              &:after {
                margin-left: 5px;
                margin-top: 0;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                display: inline-block;
              }
            }

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          &-select {
            width: 12%;
            text-align: center;
          }

          &-product {
            width: 72%;
            text-align: left;
            padding-left: 15px;

            @include breakpoint(medium) {
              padding-left: 20px;
            }
          }

          &-body {
            td {
              border: 1px solid $table-border;
            }

            &-select {
              text-align: center;
            }

            &-product {
              padding: 12px;

              @include breakpoint(medium) {
                padding: 18px 20px;
              }

              p {
                margin: 0;
              }

              .line-item__details {
                display: flex;
                align-items: center;

                &--image {
                  img {
                    width: 70px;
                    max-width: 70px;

                    @include breakpoint(medium) {
                      width: 95px;
                      max-width: 95px;
                    }
                  }
                }

                &--detail {
                  margin-left: 11px;
                  text-align: left;

                  @include breakpoint(medium) {
                    margin-left: 15px;
                  }

                  &-title {
                    font-weight: 400;

                    @include breakpoint(medium) {
                      margin-bottom: 25px;
                    }
                  }

                  &-secondary {
                    color: $secondary-text;
                    display: none;

                    @include breakpoint(medium) {
                      display: block;
                    }

                    &.exclusion {
                      color: $error;
                    }
                  }
                }
              }

              .line-item__details--detail-mobile {
                margin-top: 13px;

                @include breakpoint(medium) {
                  display: none;
                }

                &-secondary {
                  color: $secondary-text;
                  display: block;

                  &.exclusion {
                    color: $error;
                  }
                }
              }
            }

            &-price {
              font-family: $label-font;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 1px;
              text-align: center !important;
            }
          }

          tfoot {
            background-color: $table-background;
            font-family: $label-font;
            font-size: 15px;
            line-height: 15px;

            td {
              padding: 0;
              height: 46px;
            }

            .total-price {
              font-size: 14px;
            }
          }

          &-footer-mobile {
            display: table-footer-group;

            @include breakpoint(medium) {
              display: none;
            }

            .total-label {
              text-align: right;
              text-transform: uppercase;
              padding: 0 5px;

              strong {
                font-weight: 400;
              }
            }

            .total-price {
              text-align: center;
            }
          }

          &-footer-desktop {
            display: none;

            @include breakpoint(medium) {
              display: table-footer-group;
            }

            .total-label {
              text-align: right;
              text-transform: uppercase;
              padding-right: 5px;

              strong {
                font-weight: 400;
              }
            }

            .total-price {
              text-align: center;
            }
          }
        }

        &-details-title {
          color: $main-text;
          font-family: $heading-font;
          font-size: 16px;
          line-height: 24px;
          margin-top: 0;
          margin-bottom: 20px;
        }

        &-reasons {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 18px;
          margin-bottom: 20px;

          @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            margin-bottom: 24px;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-position: right center;
            background-image: url("./deadstock-ico-select.svg");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-color: $white;
            background-size: 1em;
            // text-indent: 0.01px;
            // text-overflow: "";
            cursor: pointer;
            text-align: left;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: $main-text;
            padding: 12px 16px;
            border: 1px solid $main-text;

            option {
              color: $secondary-text;
            }

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          &.full {
            grid-template-columns: 1fr;
            margin-top: 18px;
  
            @include breakpoint(medium) {
              margin-top: 16px;
            }
          }
        }

        &-comments {
          width: 100%;
          height: 137px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $main-text;
          padding: 12px 16px;
          margin-bottom: 16px;
          border: 1px solid $main-text;
          font-family: inherit;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          @include breakpoint(medium) {
            margin-bottom: 18px;
          }

          &.does-have-error {
            border: 1px solid $error;
          }
        }

        &-recaptcha {
          iframe {
            height: 78px;
          }
        }

        button {
        }
      } // End form

      &--policies {
        margin-top: 16px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 22px;
        color: $main-text;
        font-weight: 400;

        a {
          color: $main-text;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
            color: $btn-hover;
          }
        }
      }
    }
  }
}