/*================ Mixins ================*/
@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: 0px) {
      @content;
    }
  }

  @else if $point == medium {
    @media (min-width: 640px) {
      @content;
    }
  }
}

/*================ Variables ================*/
$main-text: #1B1C1E;
$secondary-text: #777777;
$white: #FFFFFF;
$error: #f0162f;
$btn-color: #000000;
$btn-hover: #FF600B;
$table-border: #C8C8C8;
$table-background: #F2F2F2;

.size-returns-app-container {
  * {
    font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .returns-app {
    &__section {
      max-width: 696px;
      margin: 60px auto 0;

      @include breakpoint(medium) {
        margin: 72px auto 0;
      }

      &--back-button {
        font-size: 14px;
        line-height: 19px;
        color: $main-text;
        text-decoration: underline;
        display: block;
        margin-bottom: 25px;
        background: none;
        border: none;
        padding: 0;

        @include breakpoint(medium) {
          margin-bottom: 40px;
        }

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: url("./ico-left.svg");
          margin-right: 8px;
        }
      }

      &--title {
        color: $main-text;
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 30px;

        @include breakpoint(medium) {
          margin-bottom: 36px;
        }
      }

      &--customer-split {
        @include breakpoint(medium) {
        }
      }

      &--customer-title {
        font-size: 14px;
        line-height: 23px;
        color: $main-text;
        margin-top: 0;
        margin-bottom: 0;

        @include breakpoint(medium) {
          margin-bottom: 5px;
        }

        &.is--right {
          @include breakpoint(medium) {
          }
        }
      }

      &--form {
        &-title {
          color: $main-text;
          font-size: 28px;
          line-height: 28px;
          font-weight: 700;
          margin-top: 0;
          margin-bottom: 20px;

          @include breakpoint(medium) {
            font-size: 32px;
            margin-bottom: 26px;
          }
        }

        &-inputs {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 18px;

          @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
          }

          div {
            display: grid;
          }

          input {
            font-size: 15px;
            font-weight: 400;
            padding: 12px 11px;
            background: $white;
            border: 1px solid $secondary-text;
            color: $main-text;
            border-radius: 0;

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          .app-has-error {
            font-size: 10px;
            padding-top: 2px;
            color: $error;
          }

          .app-no-error {
            display: none;
          }

          &.full {
            grid-template-columns: 1fr;
            margin-top: 18px;
  
            @include breakpoint(medium) {
              margin-top: 16px;
            }
          }
        }

        &-errors {
          margin-top: 18px;

          @include breakpoint(medium) {
            margin-top: 16px;
          }

          p {
            margin: 0;
            font-size: 12px;
            color: $error;
          }
        }

        &-submit {
          margin-top: 18px;
          background-color: $btn-color;
          border: 1px solid $btn-color;
          padding: 15px 0;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0;
          color: $white;
          text-align: center;
          font-weight: 400;
          transition: all .3s;

          &:hover {
            cursor: pointer;
            background-color: $btn-hover;
            border: 1px solid $btn-hover;
            color: $white;
          }

          @include breakpoint(medium) {
            margin-top: 16px;
          }

          &-text {
            position: relative;

            .is--loading {
              display: inline-block;
              position: absolute;
              left: -25px;

              &:before {
                content: url("./loading.gif");
              }
            }
          }

          &:disabled {
            opacity: 0.5;
            transition: all 0s;

            &:hover {
              background-color: $btn-color;
              color: $white;
            }
          }
        }

        &-table {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 40px;
          font-size: 13px;
          border-collapse: collapse;
          border: 1px solid $table-border;

          @include breakpoint(medium) {
            margin-top: 20px;
          }

          thead {
            background-color: $table-background;
            line-height: 15px;
            font-weight: 700;
            text-transform: uppercase;

            th {
              height: 58px;
            }
          }

          &-price {
            border-right: 1px solid $table-border;
            text-align: center;
          }

          tr {
            border: 1px solid $table-border;
          }

          input[type="checkbox"] {
            width: 16px;
            height: 16px;
            border: 0.1mm solid $main-text;
            padding: 0;
            background-color: $white;
            -webkit-appearance: none;
            -moz-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            &:checked {
              background-color: $main-text;

              &:after {
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                display: inline-block;
              }
            }

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          &-select {
            width: 12%;
            text-align: center;
          }

          &-product {
            width: 72%;
            text-align: left;
            padding-left: 15px;

            @include breakpoint(medium) {
              padding-left: 20px;
            }
          }

          &-body {
            td {
              border: 1px solid $table-border;
            }

            &-select {
              text-align: center;
            }

            &-product {
              padding: 12px;

              @include breakpoint(medium) {
                padding: 18px 20px;
              }

              p {
                margin: 0;
              }

              .line-item__details {
                display: flex;
                align-items: center;

                &--image {
                  img {
                    width: 70px;
                    max-width: 70px;

                    @include breakpoint(medium) {
                      width: 95px;
                      max-width: 95px;
                    }
                  }
                }

                &--detail {
                  margin-left: 11px;

                  @include breakpoint(medium) {
                    margin-left: 15px;
                  }

                  &-title {
                    font-weight: 400;

                    @include breakpoint(medium) {
                      margin-bottom: 25px;
                    }
                  }

                  &-secondary {
                    color: $secondary-text;
                    display: none;

                    @include breakpoint(medium) {
                      display: block;
                    }

                    &.exclusion {
                      color: $error;
                    }
                  }
                }
              }

              .line-item__details--detail-mobile {
                margin-top: 13px;

                @include breakpoint(medium) {
                  display: none;
                }

                &-secondary {
                  color: $secondary-text;
                  display: block;

                  &.exclusion {
                    color: $error;
                  }
                }
              }
            }

            &-price {
              text-align: center;
            }
          }

          tfoot {
            background-color: $table-background;
            font-size: 13px;
            line-height: 15px;

            td {
              padding: 0;
              height: 46px;
            }
          }

          &-footer-mobile {
            display: table-footer-group;

            @include breakpoint(medium) {
              display: none;
            }

            .total-label {
              text-align: right;
              text-transform: uppercase;
              padding: 0 5px;
            }

            .total-price {
              text-align: center;
            }
          }

          &-footer-desktop {
            display: none;

            @include breakpoint(medium) {
              display: table-footer-group;
            }

            .total-label {
              text-align: right;
              text-transform: uppercase;
              padding-right: 5px;
            }

            .total-price {
              text-align: center;
            }
          }
        }

        &-details-title {
          color: $main-text;
          font-size: 20px;
          line-height: 23px;
          font-weight: 700;
          margin-top: 0;
          margin-bottom: 20px;
        }

        &-reasons {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 18px;
          margin-bottom: 20px;

          @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            margin-bottom: 24px;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-position: right center;
            background-image: url("./ico-select.svg");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-color: $white;
            background-size: 1em;
            // text-indent: 0.01px;
            // text-overflow: "";
            cursor: pointer;
            text-align: left;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: $main-text;
            padding: 12px 16px;
            border: 1px solid $main-text;
            border-radius: 0;

            &.does-have-error {
              border: 1px solid $error;
            }
          }

          &.full {
            grid-template-columns: 1fr;
            margin-top: 18px;
  
            @include breakpoint(medium) {
              margin-top: 16px;
            }
          }
        }

        &-comments {
          width: 100%;
          height: 137px;
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          color: $main-text;
          padding: 12px 16px;
          margin-bottom: 16px;
          border: 1px solid $main-text;
          font-family: inherit;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 0;

          @include breakpoint(medium) {
            margin-bottom: 18px;
          }

          &.does-have-error {
            border: 1px solid $error;
          }
        }

        &-recaptcha {
          iframe {
            height: 78px;
          }
        }

        button {
        }
      } // End form

      &--policies {
        margin-top: 16px;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 20px;
        color: $main-text;
        font-weight: 400;

        a {
          color: $main-text;
          text-decoration: underline;

          &:hover {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
}
